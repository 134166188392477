import React from 'react';
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
} from 'reactstrap';
import { LangDropdown } from '../LangDropdown';
import { Link } from 'react-router-dom';
import GoSolo from '../../assets/images/go-solo.png';
// import FacebookLogo from '../../assets/images/facebook-logo.png';
// import YouTubeLogo from '../../assets/images/youtube-icon.png';

export const HeaderView = ({
  t,
  unit,
  logout,
  firstname,
  lastname,
  customer,
  history,
}) => {
  //  let getAdminMessage=async()=>{
  //    let message=await userActions.adminMessage()
  //    console.log(message)
  // }
  // getAdminMessage();
  return (
    <header>
      <div className='navbar d-flex flex-md-row flex-column align-items-end p-0'>
        <div className='logoMainCol'>
          <div className='navbar-header'>
            {!!unit && !!unit.BOSSID ? (
              <Link to={`/dashboard/units/${unit.BOSSID}`}>
                <img src={GoSolo} alt='GoSolo' className='logoMain' />
              </Link>
            ) : (
              <Link to={`/dashboard/units`}>
                <img src={GoSolo} alt='GoSolo' className='logoMain' />
              </Link>
            )}
          </div>
        </div>
        <div className='navMainCol d-flex flex-column px-0'>
          <div className='d-flex flex-row align-items-md-end navsection'>
            <Nav className='mr-auto leftNav'>
              <NavItem
                className={
                  history.location.pathname.includes('dashboard/units')
                    ? 'active'
                    : ''
                }
              >
                {!!unit && !!unit.BOSSID ? (
                  <Link to={`/dashboard/units/${unit.BOSSID}`}>
                    {t('MY DEVICES')}
                  </Link>
                ) : (
                  <Link to={`/dashboard/units`}>{t('MY DEVICES')}</Link>
                )}
              </NavItem>
              {/* <NavItem
                className={
                  history.location.pathname.includes('analytics')
                    ? 'active'
                    : ''
                }
              >
                <UncontrolledDropdown tag='a'>
                  <DropdownToggle tag='span'>{t('ANALYTICS')}</DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      disabled={history.location.pathname.includes(
                        'facebookanalytics',
                      )}
                      onClick={() => {
                        history.push(`/dashboard/facebookanalytics`);
                      }}
                    >
                      <img
                        className='fb-img socialIcon'
                        src={FacebookLogo}
                        alt='description'
                      />
                      Facebook
                    </DropdownItem>
                    <DropdownItem
                      disabled={history.location.pathname.includes(
                        'youtubeanalytics',
                      )}
                      onClick={() => {
                        history.push(`/dashboard/youtubeanalytics`);
                      }}
                    >
                      <img
                        className='yt-img socialIcon'
                        src={YouTubeLogo}
                        alt='description'
                      />
                      YouTube
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem> */}
            </Nav>
            <div className='ml-auto rightNav d-flex flex-wrap mb-auto justify-content-end'>
              {customer && (
                <Link
                  className='d-md-flex d-none referralCodeLink mr-lg-3'
                  rel='noopener noreferrer'
                  to='/dashboard/referralcode'
                >
                  {t('REFER')}
                </Link>
              )}
              <Nav className=''>
                <NavItem className='lagNav'>
                  <LangDropdown align={true} />
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown>
                    <DropdownToggle tag={Link} to='/dashboard/settings'>
                      <svg
                        height='27px'
                        className='user_svg'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 55 55'
                      >
                        <path d='M55,27.5C55,12.337,42.663,0,27.5,0S0,12.337,0,27.5c0,8.009,3.444,15.228,8.926,20.258l-0.026,0.023l0.892,0.752 c0.058,0.049,0.121,0.089,0.179,0.137c0.474,0.393,0.965,0.766,1.465,1.127c0.162,0.117,0.324,0.234,0.489,0.348 c0.534,0.368,1.082,0.717,1.642,1.048c0.122,0.072,0.245,0.142,0.368,0.212c0.613,0.349,1.239,0.678,1.88,0.98 c0.047,0.022,0.095,0.042,0.142,0.064c2.089,0.971,4.319,1.684,6.651,2.105c0.061,0.011,0.122,0.022,0.184,0.033 c0.724,0.125,1.456,0.225,2.197,0.292c0.09,0.008,0.18,0.013,0.271,0.021C25.998,54.961,26.744,55,27.5,55 c0.749,0,1.488-0.039,2.222-0.098c0.093-0.008,0.186-0.013,0.279-0.021c0.735-0.067,1.461-0.164,2.178-0.287 c0.062-0.011,0.125-0.022,0.187-0.034c2.297-0.412,4.495-1.109,6.557-2.055c0.076-0.035,0.153-0.068,0.229-0.104 c0.617-0.29,1.22-0.603,1.811-0.936c0.147-0.083,0.293-0.167,0.439-0.253c0.538-0.317,1.067-0.648,1.581-1 c0.185-0.126,0.366-0.259,0.549-0.391c0.439-0.316,0.87-0.642,1.289-0.983c0.093-0.075,0.193-0.14,0.284-0.217l0.915-0.764 l-0.027-0.023C51.523,42.802,55,35.55,55,27.5z M2,27.5C2,13.439,13.439,2,27.5,2S53,13.439,53,27.5 c0,7.577-3.325,14.389-8.589,19.063c-0.294-0.203-0.59-0.385-0.893-0.537l-8.467-4.233c-0.76-0.38-1.232-1.144-1.232-1.993v-2.957 c0.196-0.242,0.403-0.516,0.617-0.817c1.096-1.548,1.975-3.27,2.616-5.123c1.267-0.602,2.085-1.864,2.085-3.289v-3.545 c0-0.867-0.318-1.708-0.887-2.369v-4.667c0.052-0.52,0.236-3.448-1.883-5.864C34.524,9.065,31.541,8,27.5,8 s-7.024,1.065-8.867,3.168c-2.119,2.416-1.935,5.346-1.883,5.864v4.667c-0.568,0.661-0.887,1.502-0.887,2.369v3.545 c0,1.101,0.494,2.128,1.34,2.821c0.81,3.173,2.477,5.575,3.093,6.389v2.894c0,0.816-0.445,1.566-1.162,1.958l-7.907,4.313 c-0.252,0.137-0.502,0.297-0.752,0.476C5.276,41.792,2,35.022,2,27.5z' />
                      </svg>
                    </DropdownToggle>
                  </UncontrolledDropdown>
                </NavItem>

                <NavItem>
                  <UncontrolledDropdown>
                    <DropdownToggle tag='a'>
                      <svg
                        className='hamburgar_svg'
                        height='27px'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                      >
                        <g>
                          <path d='M24,3c0-0.6-0.4-1-1-1H1C0.4,2,0,2.4,0,3v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V3z' />
                          <path d='M24,11c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V11z' />
                          <path d='M24,19c0-0.6-0.4-1-1-1H1c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V19z' />
                        </g>
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag={Link} to='/dashboard/paymentinfo'>
                        <i className='fa fa-file-text fa-fw'></i>
                        {t('PAYMENTINFO')}
                      </DropdownItem>
                      <DropdownItem tag={Link} to='/dashboard/paymenthistory'>
                        <i className='fa fa-usd fa-fw'></i>
                        {t('PAYMENTHISTORY')}
                      </DropdownItem>
                      <DropdownItem tag={Link} to='/dashboard/paymentmethod'>
                        <i className='fa fa-money fa-fw'></i>
                        {t('PAYMENTMETHOD')}
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        to='/dashboard/managedestination'
                      >
                        <i className='fa fa-edit fa-fw'></i>
                        {t('MANAGEDESTINATION')}
                      </DropdownItem>
                      <DropdownItem
                        href='https://solohelp.liveu.tv/'
                        ng-if='newTab'
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <i className='fa fa-wrench' aria-hidden='true'></i>
                        {t('SUPPORT')}
                      </DropdownItem>

                      <DropdownItem divider />
                      <DropdownItem onClick={logout}>
                        <i className='fa fa-sign-out fa-fw'></i>
                        {t('LOGOUT')}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </Nav>
              <div className='w-100 text-right'>
                <div className='userName font-weight-bold d-none d-md-block'>
                  {firstname} {lastname}
                </div>
              </div>
            </div>
          </div>
          <div className='userName  pr-2 pt-1 d-md-none d-flex '>
            <Link
              className=' referralCodeLink pl-2 mr-auto'
              rel='noopener noreferrer'
              to='/dashboard/referralcode'
            >
              {t('REFER')}
            </Link>
            <span className='text-right ml-auto'>
              {firstname} {lastname}
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
