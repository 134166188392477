import React from 'react';

import FB from '../../assets/images/cdn12.png';
import FBxs from '../../assets/images/fb-xs.png';
import YTxs from '../../assets/images/youtube-xs.png';
// import YT from '../../assets/images/cdn14.png';
import Wowzaxs from '../../assets/images/wowza-xs.png';
import Wowza from '../../assets/images/wowza.png';
import LinkedIn from '../../assets/images/linkedin.png';
import LinkedInxs from '../../assets/images/linkedin-xs.png';
import ELxs from '../../assets/images/easylive-xs.png';
import EL from '../../assets/images/easylive.png';
import SBxs from '../../assets/images/joicaster-xs.png';
import SB from '../../assets/images/joicaster.png';
import PTxs from '../../assets/images/twitter-xs.jpg';
import PT from '../../assets/images/twitter.jpg';
import Twitchxs from '../../assets/images/twitch-xs.png';
import Twitch from '../../assets/images/twitch.png';
import RSxs from '../../assets/images/restream-xs.png';
import RS from '../../assets/images/restream.png';
import Vimeoxs from '../../assets/images/vimeo-xs.png';
import Vimeo from '../../assets/images/vimeo.png';
import BCxs from '../../assets/images/boxcast-xs.png';
import BC from '../../assets/images/boxcast.png';
import PSxs from '../../assets/images/playsight-xs.png';
import PS from '../../assets/images/playsight.png';
import TVCloudxs from '../../assets/images/telvue_cloudcast-xs.png';
import TVCloud from '../../assets/images/telvue_cloudcast.png';
import TVHyperxs from '../../assets/images/telvue_hypercaster-xs.png';
import TVHyper from '../../assets/images/telvue_hypercaster.png';
import Psynapsxs from '../../assets/images/psynaps-xs.png';
import Psynaps from '../../assets/images/psynaps.png';
import VLxs from '../../assets/images/videolinq-xs.png';
import VL from '../../assets/images/videolinq.png';
import Streannxs from '../../assets/images/streann-xs.png';
import Streann from '../../assets/images/streann.png';
import Muduxs from '../../assets/images/mudu-xs.png';
import Mudu from '../../assets/images/mudu.png';
import Jstreamxs from '../../assets/images/jstream-xs.png';
import Jstream from '../../assets/images/jstream.png';
import ESxs from '../../assets/images/everstream-xs.png';
import ES from '../../assets/images/everstream.png';
import ESPxs from '../../assets/images/espxmedia-xs.png';
import ESP from '../../assets/images/espxmedia.png';
import Dayangxs from '../../assets/images/dayang-xs.png';
import Dayang from '../../assets/images/dayang.png';
import Vidxs from '../../assets/images/vpress1-xs.png';
import Vid from '../../assets/images/vpress1.png';
import CCastxs from '../../assets/images/ccast1-xs.png';
import CCast from '../../assets/images/ccast1.png';
import LLxs from '../../assets/images/limelight-xs.png';
import LL from '../../assets/images/limelight.png';
import GCorexs from '../../assets/images/gcdn-xs.png';
import GCore from '../../assets/images/gcdn.png';
import Tulixxs from '../../assets/images/tulix-xs.png';
import Tulix from '../../assets/images/tulix.png';
import CDNxs from '../../assets/images/cdnvideo-xs.png';
import CDN from '../../assets/images/cdnvideo.png';
import DMxs from '../../assets/images/dailymotion-xs.png';
import DM from '../../assets/images/dailymotion.png';
import Level3xs from '../../assets/images/level3-xs.png';
import Level3 from '../../assets/images/level3.png';
import KTxs from '../../assets/images/kaltura-xs.png';
import KT from '../../assets/images/kaltura.png';
import OOxs from '../../assets/images/ooyala-xs.png';
import OO from '../../assets/images/ooyala.png';
import BrCxs from '../../assets/images/brightcove-xs.png';
import BrC from '../../assets/images/brightcove.png';
import AKxs from '../../assets/images/akamai-xs.png';
import AK from '../../assets/images/akamai.png';
import Strexs from '../../assets/images/stre-am-xs.png';
import Stre from '../../assets/images/stre-am.png';
import DCxs from '../../assets/images/dacast-xs.png';
import DC from '../../assets/images/dacast.png';
import USxs from '../../assets/images/ustream-xs.png';
import US from '../../assets/images/ustream.png';
import MYCxs from '../../assets/images/mycujoo-xs.png';
import MYC from '../../assets/images/mycujoo.png';
import RTMPxs from '../../assets/images/rtmp-xs.png';
import RTMP from '../../assets/images/rtmp.png';
import DALS from '../../assets/images/dals.png';
import DALSxs from '../../assets/images/DALS-xs.png';
import MSStreamxs from '../../assets/images/MicrosoftStream-xs.png';
import MSStream from '../../assets/images/MicrosoftStream.png';
import MSTeamsxs from '../../assets/images/MicrosoftTeams-xs.png';
import MSTeams from '../../assets/images/MicrosoftTeams.png';
import OBSser from '../../assets/images/obs-server.png';
import LS from '../../assets/images/Lightstream.png';
import GRA from '../../assets/images/Grabyo.png';
import IRLTK from '../../assets/images/irltk.png';
import NDJ_BR from '../../assets/images/nadaje-broadcasting.jpg';
import JMB from '../../assets/images/jumbo.jpg';
import AL from '../../assets/images/amazon-live.png';
import ALxs from '../../assets/images/amazon-live-xs.png';
import SL from '../../assets/images/smartlive.png';
import SLxs from '../../assets/images/smartlive-xs.png';
import EVENTLIVE from '../../assets/images/event-live.png';
import EVENTLIVExs from '../../assets/images/event-live-xs.png';
import CASTR from '../../assets/images/castr.png';
import SRT from '../../assets/images/srt.png';
import SRTxs from '../../assets/images/srt-xs.png';
import KAIROS from '../../assets/images/kairos.png';
import KAIROSxs from '../../assets/images/kairos-xs.png';
import ANTISCUFF from '../../assets/images/antiscuff.png';
import ANTISCUFFxs from '../../assets/images/antiscuff-xs.png';

export const ProvidersView = ({ provider, isSmall }) => {
  function List(provider, isSmall) {
    switch (provider) {
      case 'Facebook':
        return (
          <img src={isSmall ? FBxs : FB} alt={provider} title={provider} />
        );
      case 'youtube':
      case 'YouTube':
        return (
          <img src={isSmall ? YTxs : YTxs} alt={provider} title={provider} />
        );
      case 'wowza':
        return (
          <img
            src={isSmall ? Wowzaxs : Wowza}
            alt={provider}
            title={provider}
          />
        );
      case 'LinkedIn':
        return (
          <img
            src={isSmall ? LinkedInxs : LinkedIn}
            alt={provider}
            title={provider}
          />
        );
      case 'Easy Live':
        return (
          <img src={isSmall ? ELxs : EL} alt={provider} title={provider} />
        );
      case 'Switchboard Live':
        return (
          <img src={isSmall ? SBxs : SB} alt={provider} title={provider} />
        );
      case 'Periscope and Twitter':
        return (
          <img
            className='enlargeImg'
            src={isSmall ? PTxs : PT}
            alt='Twitter'
            title='Twitter'
          />
        );
      case 'Twitch':
        return (
          <img
            src={isSmall ? Twitchxs : Twitch}
            alt={provider}
            title={provider}
          />
        );
      case 'Restream.io':
        return (
          <img
            className='enlargeImg'
            src={isSmall ? RSxs : RS}
            alt={provider}
            title={provider}
          />
        );
      case 'Vimeo':
        return (
          <img
            src={isSmall ? Vimeoxs : Vimeo}
            alt={provider}
            title={provider}
          />
        );
      case 'Boxcast':
        return (
          <img src={isSmall ? BCxs : BC} alt={provider} title={provider} />
        );
      case 'Playsight':
        return (
          <img src={isSmall ? PSxs : PS} alt={provider} title={provider} />
        );
      case 'TelVue CloudCast':
        return (
          <img
            src={isSmall ? TVCloudxs : TVCloud}
            alt={provider}
            title={provider}
          />
        );
      case 'TelVue HyperCaster':
        return (
          <img
            src={isSmall ? TVHyperxs : TVHyper}
            alt={provider}
            title={provider}
          />
        );
      case 'Psynaps':
        return (
          <img
            src={isSmall ? Psynapsxs : Psynaps}
            alt={provider}
            title={provider}
          />
        );
      case 'Wowza Streaming Engine':
        return (
          <img
            src={isSmall ? Wowzaxs : Wowza}
            alt={provider}
            title={provider}
          />
        );
      case 'Videolinq':
        return (
          <img src={isSmall ? VLxs : VL} alt={provider} title={provider} />
        );
      case 'Streann':
        return (
          <img
            src={isSmall ? Streannxs : Streann}
            alt={provider}
            title={provider}
          />
        );
      case 'Mudu':
        return (
          <img src={isSmall ? Muduxs : Mudu} alt={provider} title={provider} />
        );
      case 'Jstream':
        return (
          <img
            src={isSmall ? Jstreamxs : Jstream}
            alt={provider}
            title={provider}
          />
        );
      case 'Everstream':
        return (
          <img src={isSmall ? ESxs : ES} alt={provider} title={provider} />
        );
      case 'ESPxMedia':
        return (
          <img src={isSmall ? ESPxs : ESP} alt={provider} title={provider} />
        );
      case 'Dayang':
        return (
          <img
            src={isSmall ? Dayangxs : Dayang}
            alt={provider}
            title={provider}
          />
        );
      case 'Vidpresso':
        return (
          <img src={isSmall ? Vidxs : Vid} alt={provider} title={provider} />
        );
      case 'Crowdcast.io':
        return (
          <img
            src={isSmall ? CCastxs : CCast}
            alt={provider}
            title={provider}
          />
        );
      case 'Limelight':
        return (
          <img src={isSmall ? LLxs : LL} alt={provider} title={provider} />
        );
      case 'G-Core':
        return (
          <img
            src={isSmall ? GCorexs : GCore}
            alt={provider}
            title={provider}
          />
        );
      case 'Tulix':
        return (
          <img
            src={isSmall ? Tulixxs : Tulix}
            alt={provider}
            title={provider}
          />
        );
      case 'CDNvideo':
        return (
          <img src={isSmall ? CDNxs : CDN} alt={provider} title={provider} />
        );
      case 'dailymotion':
        return (
          <img src={isSmall ? DMxs : DM} alt={provider} title={provider} />
        );
      case 'Level3':
        return (
          <img
            src={isSmall ? Level3xs : Level3}
            alt={provider}
            title={provider}
          />
        );
      case 'Kaltura':
        return (
          <img src={isSmall ? KTxs : KT} alt={provider} title={provider} />
        );
      case 'Ooyala':
        return (
          <img src={isSmall ? OOxs : OO} alt={provider} title={provider} />
        );
      case 'Brightcove':
        return (
          <img src={isSmall ? BrCxs : BrC} alt={provider} title={provider} />
        );
      case 'Akamai':
        return (
          <img src={isSmall ? AKxs : AK} alt={provider} title={provider} />
        );
      case 'Stre.am':
        return (
          <img src={isSmall ? Strexs : Stre} alt={provider} title={provider} />
        );
      case 'dacast':
        return (
          <img src={isSmall ? DCxs : DC} alt={provider} title={provider} />
        );
      case 'Ustream':
        return (
          <img src={isSmall ? USxs : US} alt={provider} title={provider} />
        );
      case 'mycujoo':
        return (
          <img src={isSmall ? MYCxs : MYC} alt={provider} title={provider} />
        );
      case 'DALS Ultra Low Latency':
        return (
          <img src={isSmall ? DALSxs : DALS} alt={provider} title={provider} />
        );
      case 'Microsoft Stream':
        return (
          <img
            src={isSmall ? MSStreamxs : MSStream}
            alt={provider}
            title={provider}
          />
        );
      case 'Microsoft Teams':
        return (
          <img
            src={isSmall ? MSTeamsxs : MSTeams}
            alt={provider}
            title={provider}
          />
        );
      case 'Lightstream':
        return <img src={isSmall ? LS : LS} alt={provider} title={provider} />;
      case 'Grabyo':
        return (
          <img src={isSmall ? GRA : GRA} alt={provider} title={provider} />
        );
      case 'OBS-Server':
        return (
          <img
            src={isSmall ? OBSser : OBSser}
            alt={provider}
            title={provider}
          />
        );
      case 'Jumbo':
        return (
          <img src={isSmall ? JMB : JMB} alt={provider} title={provider} />
        );
      case 'IRLToolkit':
        return (
          <img src={isSmall ? IRLTK : IRLTK} alt={provider} title={provider} />
        );
      case 'Nadaje Broadcasting':
        return (
          <img
            src={isSmall ? NDJ_BR : NDJ_BR}
            alt={provider}
            title={provider}
          />
        );
      case 'Amazon Live':
        return (
          <img src={isSmall ? ALxs : AL} alt={provider} title={provider} />
        );
      case 'Smart vLive':
        return (
          <img src={isSmall ? SLxs : SL} alt={provider} title={provider} />
        );
      case 'EventLive':
        return (
          <img
            src={isSmall ? EVENTLIVExs : EVENTLIVE}
            alt={provider}
            title={provider}
          />
        );
      case 'Castr':
        return (
          <img src={isSmall ? CASTR : CASTR} alt={provider} title={provider} />
        );
      case 'SRT-OUT-Caller-Solo':
        return (
          <img src={isSmall ? SRTxs : SRT} alt={provider} title={provider} />
        );
      case 'KAIROS Cloud Service (Japan)':
        return (
          <img
            src={isSmall ? KAIROSxs : KAIROS}
            alt={provider}
            title={provider}
          />
        );
      case 'AntiScuff':
        return (
          <img
            src={isSmall ? ANTISCUFFxs : ANTISCUFF}
            alt={provider}
            title={provider}
          />
        );
      default:
        return (
          <img src={isSmall ? RTMPxs : RTMP} alt={provider} title={provider} />
        );
    }
  }

  return <span className='img'>{List(provider, isSmall)}</span>;
};
