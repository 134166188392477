import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { Dashboard } from '../Dashboard';
import { Facebook } from '../Providers/Facebook';
import { ErrorBoundary } from '../ErrorBoundary';
import { Liveuloading } from '../Liveuloading';
import lazycomponentLoader from '../LazyComponentLoader/LazyComponentLoader';
import { useDispatch } from 'react-redux';
import { userActions } from '../../actions';
const YouTube = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/YouTube')),
);
const EditYouTube = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/EditYouTube')),
);
const YouTubeConfigure = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/YouTube/YouTubeConfigure')),
);
const YouTubeHelp = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/YouTube/YouTubeHelp')),
);
const Linkedin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Linkedin')),
);
const LinkedinAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Linkedin/LinkedinAuth')),
);
const LinkedinLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Linkedin/LinkedinLogin')),
);
const Switchboard = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Switchboard')),
);
const SwitchboardAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Switchboard/SwitchboardAuth')),
);
const SwitchboardLogin = React.lazy(() =>
  lazycomponentLoader(() =>
    import('../Providers/Switchboard/SwitchboardLogin'),
  ),
);
// const FbAnalytics = React.lazy(() =>
//   lazycomponentLoader(() => import('../Header/FbAnalytics')),
// );
// const YTAnalytics = React.lazy(() =>
//   lazycomponentLoader(() => import('../Header/YTAnalytics')),
// );
const Twitch = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Twitch')),
);
const TwitchAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Twitch/TwitchAuth')),
);
const TwitchLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Twitch/TwitchLogin')),
);
const Restream = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Restream')),
);
const RestreamAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Restream/RestreamAuth')),
);
const RestreamLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Restream/RestreamLogin')),
);
const Vimeo = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Vimeo')),
);
const VimeoLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Vimeo/VimeoLogin')),
);
const VimeoAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Vimeo/VimeoAuth')),
);
const BoxCast = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/BoxCast')),
);
const BoxCastAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/BoxCast/BoxCastAuth')),
);
const BoxCastLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/BoxCast/BoxCastLogin')),
);
const Castr = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Castr')),
);
const CastrForm = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Castr/CastrForm')),
);
const Wowza = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Wowza')),
);
const Wowzahelp = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Wowza/Wowzahelp')),
);
const Wowzartmp = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Wowza/Wowzartmp')),
);
const Castrtmp = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/Castr/Castrtmp')),
);
const PeriscopeTwitterAuth = React.lazy(() =>
  lazycomponentLoader(() =>
    import('../Providers/PeriscopeTwitter/PeriscopeTwitterAuth'),
  ),
);
const PeriscopeTwitter = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/PeriscopeTwitter')),
);
const PeriscopeTwitterLogin = React.lazy(() =>
  lazycomponentLoader(() =>
    import('../Providers/PeriscopeTwitter/PeriscopeTwitterLogin'),
  ),
);
const EasyLiveLogin = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/EasyLive/EasyLiveLogin')),
);
const EasyLiveAuth = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/EasyLive/EasyLiveAuth')),
);
const EasyLive = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/EasyLive')),
);

const SubscriptionInfo = React.lazy(() =>
  lazycomponentLoader(() => import('../SubscriptionInfo')),
);
const PaymentMethod = React.lazy(() =>
  lazycomponentLoader(() => import('../PaymentMethod')),
);
const ReferralCode = React.lazy(() =>
  lazycomponentLoader(() => import('../ReferralCode')),
);
const PaymentHistory = React.lazy(() =>
  lazycomponentLoader(() => import('../PaymentHistory')),
);
const Invoice = React.lazy(() =>
  lazycomponentLoader(() => import('../Invoice')),
);
const ManageDestination = React.lazy(() =>
  lazycomponentLoader(() => import('../ManageDestination')),
);
const Settings = React.lazy(() =>
  lazycomponentLoader(() => import('../Settings')),
);
const Editunit = React.lazy(() =>
  lazycomponentLoader(() => import('../Editunit')),
);
const UnitSettings = React.lazy(() =>
  lazycomponentLoader(() => import('../UnitSettings')),
);
const CustomRtmp = React.lazy(() =>
  lazycomponentLoader(() => import('../Providers/CustomRtmp')),
);
const AddUnit = React.lazy(() =>
  lazycomponentLoader(() => import('../AddUnit')),
);
const UnitServices = React.lazy(() =>
  lazycomponentLoader(() => import('../UnitServices')),
);
export const TemplateView = ({ t, match, state }) => {
  const dispatch = useDispatch();
  const [haltWhilepreparingState, setHaltWhilepreparingState] = useState(true);
  useEffect(() => {
    (async function () {
      await dispatch(userActions.setStreamToolsConfig());
      setHaltWhilepreparingState(false);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div id='wrapper' className='liveu_page'>
      <ErrorBoundary resetState={state}>
        <Header />
      </ErrorBoundary>
      {haltWhilepreparingState ? (
        <div id='page-wrapper' className='mainContentArea'></div>
      ) : (
        <div id='page-wrapper' className='mainContentArea'>
          <Switch>
            <Route path={`${match.url}/units`} component={Dashboard} />
            <ErrorBoundary resetState={state}>
              <Switch>
                <Route
                  exact
                  path={`${match.url}/facebook/:bossId/:fbId`}
                  component={Facebook}
                />
                <Route
                  exact
                  path={`${match.url}/facebook/:bossId`}
                  component={Facebook}
                />
                <Route
                  exact
                  path={`${match.url}/editfacebook/:bossId/:postId/:fbId`}
                  component={Facebook}
                />
                <Suspense fallback={<Liveuloading />}>
                  <Switch>
                    <Route
                      exact
                      path={`${match.url}/unitservices/:bossId`}
                      component={UnitServices}
                    />
                    {/* <Route
                      exact
                      path={`${match.url}/facebookanalytics`}
                      component={FbAnalytics}
                    />
                    <Route
                      exact
                      path={`${match.url}/youtubeanalytics`}
                      component={YTAnalytics}
                    /> */}
                    <Route
                      exact
                      path={`${match.url}/paymentinfo`}
                      component={SubscriptionInfo}
                    />
                    <Route
                      exact
                      path={`${match.url}/paymentmethod`}
                      component={PaymentMethod}
                    />
                    <Route
                      exact
                      path={`${match.url}/referralcode`}
                      component={ReferralCode}
                    />
                    <Route
                      exact
                      path={`${match.url}/paymenthistory`}
                      component={PaymentHistory}
                    />
                    <Route
                      exact
                      path={`${match.url}/invoice/:invoiceId`}
                      component={Invoice}
                    />
                    <Route
                      exact
                      path={`${match.url}/add`}
                      component={AddUnit}
                    />
                    <Route
                      exact
                      path={`${match.url}/managedestination`}
                      component={ManageDestination}
                    />
                    <Route
                      exact
                      path={`${match.url}/settings`}
                      component={Settings}
                    />
                    <Route
                      exact
                      path={`${match.url}/edit/:bossId`}
                      component={Editunit}
                    />
                    <Route
                      exact
                      path={`${match.url}/:bossId/settings`}
                      component={UnitSettings}
                    />
                    <Route
                      exact
                      path={`${match.url}/rtmp/:provider/:bossId`}
                      component={CustomRtmp}
                    />
                    <Route
                      exact
                      path={`${match.url}/editrtmp/:provider/:bossId/:destId`}
                      component={CustomRtmp}
                    />
                    <Route
                      exact
                      path={`${match.url}/linkedin/:bossId`}
                      component={Linkedin}
                    />
                    <Route
                      path={`${match.url}/linkedinauth*`}
                      component={LinkedinAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/linkedinlogin/:bossId`}
                      component={LinkedinLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/switchboard/:bossId`}
                      component={Switchboard}
                    />
                    <Route
                      path={`${match.url}/switchboardauth*`}
                      component={SwitchboardAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/switchboardlogin/:bossId`}
                      component={SwitchboardLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/restream/:bossId`}
                      component={Restream}
                    />
                    <Route
                      path={`${match.url}/restreamauth*`}
                      component={RestreamAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/restreamlogin/:bossId`}
                      component={RestreamLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/twitch/:bossId`}
                      component={Twitch}
                    />
                    <Route
                      path={`${match.url}/twitchauth*`}
                      component={TwitchAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/twitchlogin/:bossId`}
                      component={TwitchLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/boxcast/:bossId`}
                      component={BoxCast}
                    />
                    <Route
                      path={`${match.url}/boxcastauth*`}
                      component={BoxCastAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/boxcastlogin/:bossId`}
                      component={BoxCastLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/vimeo/:bossId/:destId?`}
                      component={Vimeo}
                    />
                    <Route
                      path={`${match.url}/vimeoauth*`}
                      component={VimeoAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/vimeologin/:bossId`}
                      component={VimeoLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/castr/:bossId`}
                      component={Castr}
                    />
                    <Route
                      exact
                      path={`${match.url}/castrForm/:bossId`}
                      component={CastrForm}
                    />
                    <Route
                      exact
                      path={`${match.url}/wowza/:bossId`}
                      component={Wowza}
                    />
                    <Route
                      exact
                      path={`${match.url}/wowzahelp`}
                      component={Wowzahelp}
                    />
                    <Route
                      exact
                      path={`${match.url}/wowzartmp/:bossId`}
                      component={Wowzartmp}
                    />
                    <Route
                      exact
                      path={`${match.url}/castrtmp/:bossId`}
                      component={Castrtmp}
                    />
                    <Route
                      path={`${match.url}/twitterauth*`}
                      component={PeriscopeTwitterAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/twitterlogin/:bossId`}
                      component={PeriscopeTwitterLogin}
                    />
                    <Route
                      exact
                      path={`${match.url}/twitter/:bossId`}
                      component={PeriscopeTwitter}
                    />
                    <Route
                      exact
                      path={`${match.url}/easylivelogin/:bossId`}
                      component={EasyLiveLogin}
                    />
                    <Route
                      path={`${match.url}/easyliveauth*`}
                      component={EasyLiveAuth}
                    />
                    <Route
                      exact
                      path={`${match.url}/easylive/:bossId`}
                      component={EasyLive}
                    />
                    <Route
                      exact
                      path={`${match.url}/youtube/:bossId`}
                      component={YouTube}
                    />
                    <Route
                      exact
                      path={`${match.url}/edityoutube/:bossId`}
                      component={EditYouTube}
                    />
                    <Route
                      exact
                      path={`${match.url}/configureyoutube/:bossId`}
                      component={YouTubeConfigure}
                    />
                    <Route
                      exact
                      path={`${match.url}/youtubehelp`}
                      component={YouTubeHelp}
                    />
                    <Route
                      exact
                      path='*'
                      render={() => <Redirect to='/dashboard/units' />}
                    />
                  </Switch>
                </Suspense>
              </Switch>
            </ErrorBoundary>
          </Switch>
        </div>
      )}
      <ErrorBoundary resetState={state}>
        <Footer t={t} />
      </ErrorBoundary>
    </div>
  );
};
